import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Menu, MenuItem } from '@szhsin/react-menu';
import { BurgerTrigger } from "./ui";

export const menu = [
	{
		key: "menu-item-events",
		displayName: "Мероприятия",
		href: "/meropriyatiya"
	},
	{
		key: "menu-item-about",
		displayName: "О бюро",
		href: "/o-buro",
		children: [
			{
				key: "menu-item-structure",
				displayName: "Структура Бюро",
				href: "/struktura"
			},
			{
				key: "menu-item-doctors",
				displayName: "Информация о врачах",
				href: "/vrachi"
			},
			{
				key: "menu-item-honored-doctors",
				displayName: "Заслуженные врачи РФ",
				href: "/zasluzhennie-vrachi"
			},
			{
				key: "menu-item-profsoyuz",
				displayName: "Профсоюз бюро",
				href: "/profsoyuz-buro"
			},
			{
				key: "menu-item-profsoyuz",
				displayName: "Контролирующие органы",
				href: "/kontroliruyushie-organi"
			},

		]
	},
	{
		key: "menu-item-vakansii",
		displayName: "Вакансии",
		href: "/vakansii"
	},
	{
		key: "menu-item-contacts",
		displayName: "Контакты",
		href: "/kontakti",
		children: [
			{
				key: "menu-item-contacts",
				displayName: "Контакты",
				href: "/kontakti",
			},
			{
				key: "menu-item-connect",
				displayName: "Обратная связь",
				href: "/obratnaya-svyaz",
			},
			{
				key: "menu-item-all-departments",
				displayName: "Административно-управленческий персонал",
				href: "/administrativno-upravvlencheskij-personal"
			},
			{
				key: "menu-item-all-departments",
				displayName: "Судебно-медицинские отделения",
				href: "/sudebno-mediczinskie-otdely"
			},
			{
				key: "menu-item-pao-departments",
				displayName: "Районные патологоанатомические отделения",
				href: "./pdf/contacts/contacts-pao.pdf"
			},
		]
	},
	{
		key: "menu-item-documents",
		displayName: "Документы",
		href: "/dokumenti",
		children: [
			{
				key: "menu-item-documents-code",
				displayName: "Устав ГКУЗ ЛО БСМЭ",
				href: "./pdf/documents/code.pdf"
			},
			{
				key: "menu-item-documents-license",
				displayName: "Лицензия",
				href: "./pdf/documents/med-license.pdf"
			},
			{
				key: "menu-item-documents-registration",
				displayName: "Государственная регистрация",
				href: "./pdf/documents/registration.pdf"
			},
			{
				key: "menu-item-documents-extract-license",
				displayName: "Выписка из реестра лицензий",
				href: "./pdf/documents/extract-license.pdf"
			},
			{
				key: "menu-item-documents-taxes",
				displayName: "Постановка на налоговый учет",
				href: "./pdf/documents/taxes.pdf"
			},

			{
				key: "menu-item-documents-examples",
				displayName: "Образцы запросов",
				href: "/obrazci-zaprosov"
			},

			// {
			// 	key: "menu-item-documents-acts",
			// 	displayName: "Нормативные акты и положения",
			// 	href: "/normativnie-akti"
			// },
		]
	},

];

function Header(props) {
	const {
		setIsBurgerOpened,
		isVisionMode,
		setIsVisionMode
	} = props;
	const navigate = useNavigate();

	const toggleVisionMode = () => {
		localStorage.setItem('vision', isVisionMode ? '0' : '1');
		setIsVisionMode(!isVisionMode);
	};

	return (
		<div className="flex flex-col w-full">
			<TransparentBlock className={`p-4 md:py-6 md:px-12 ${localStorage.getItem('vision') === '1' ? '' : 'lg:px-48'} flex justify-between items-center lg:justify-start gap-4 xl:gap-8`}>
				<a href="/" className="hover:brightness-75 outline-none">
					<img className="hidden md:block" src="./img/icon.webp" alt="logo" height="120" width="120"/>
					<img className="md:hidden" src="./img/icon.webp" alt="logo" height="80" width="80"/>
				</a>
				<div className="hidden lg:flex gap-4 justify-between w-full">
					<Contact>
						Санкт-Петербург,<br/>ул. Шкапина, д.<br/>36-38-40 литер «Б»
					</Contact>
					<Contact>
						Режим работы<br/>администрации: <br/>пн-пт<br/> 9:00 - 17:30
					</Contact>
					<div className="flex flex-col gap-2">
						<div className="flex flex-col flex-1 justify-center gap-2">
							<ContactPhone href="tel:+78122523124">
								<img src="./svg/phone.svg" alt="phone" width={ 20 }/>
								+7 (812) 252-31-24
							</ContactPhone>
							<ContactPhone href="tel:+78122524933">
								<img src="./svg/phone.svg" alt="phone" width={ 20 }/>
								+7 (812) 252-49-33
							</ContactPhone>
						</div>
						<ContactPhone href="mailto:expertfm@mail.ru">
							<img src="./svg/email.svg" alt="email" width={ 20 }/>
							expertfm@mail.ru
						</ContactPhone>
					</div>
				</div>
				<button
					onClick={toggleVisionMode}
					className="hidden lg:block hover:brightness-75"
				>
					<img src={isVisionMode ? "./svg/eye-slash.svg" : "./svg/eye.svg"} alt="vision-icon" width={100} height={100}/>
				</button>
				<div className="flex lg:hidden gap-4 md:gap-8">
					<button
						className="hover:brightness-75"
						onClick={toggleVisionMode}
					>
						<img src={isVisionMode ? "./svg/eye-slash.svg" : "./svg/eye.svg"} alt="vision-icon" width={60} height={60}/>
					</button>
					<BurgerTrigger setIsOpened={setIsBurgerOpened}/>
				</div>

			</TransparentBlock>
			<TransparentBlock className="hidden lg:flex justify-around items-center">
				{
					menu.map( el => {
						return el.children && el.children.length
							? (
								<HeaderMenuDropdown
									key={ el.key }
									menuButton={
										<HeaderMenuItem
											className="p-4 flex-1 flex items-center justify-center"
										>
											{ el.displayName }
										</HeaderMenuItem>
									}
								>
									{
										el.children.map( child => {
											return (
												child.href.startsWith( "./" )
													?
														<a href={ child.href } key={ child.key } target="_blank" rel="noreferrer">
															<HeaderMenuDropdownItem
															>
																{ child.displayName }
															</HeaderMenuDropdownItem>
														</a>


													: <HeaderMenuDropdownItem
														key={ child.key }
														onClick={ () => {
															navigate( child.href )
														} }
													>
														{ child.displayName }
													</HeaderMenuDropdownItem>
											);
										} )
									}
								</HeaderMenuDropdown>
							)
							: (
								<HeaderMenuItem
									className="p-4 flex-1 flex items-center justify-center"
									key={ el.key }
									onClick={ () => navigate( el.href ) }
								>
									{ el.displayName }
								</HeaderMenuItem>
							);
					} )
				}

			</TransparentBlock>
		</div>
	);
}

export default Header;

export const Contact = styled.div`
    flex: 1 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f3f3f3;
`;

export const ContactPhone = styled.a`
    outline: none;
    display: flex;
    align-items: center;
    gap: 4px;
    color: #f3f3f3;
    
    &:hover{
        filter: brightness(75%);
    }
`;

export const TransparentBlock = styled.div`
    background-color: rgba(63,63,63);
    
    backdrop-filter: blur(4px);
    
    border-bottom: 1px solid #f3f3f3;
    &:first-child{
        
    }
    
`;

export const HeaderMenuItem = styled.div`
    text-decoration: none;
    cursor: pointer;
    border-left: 1px solid #f3f3f3;
    color: #f3f3f3;
    
    &:hover{
        background-color: rgba(31,31,31);
    }
    
    &:first-child{
        border-left: none;
    }
    
`;

export const HeaderMenuDropdown = styled( Menu )`
	ul{
		width: calc(100vw/5 - 2px);
        border: 1px solid #f3f3f3;
        
	}
`;

export const HeaderMenuDropdownItem = styled( MenuItem )`
	width: 100%;
	padding: 0.75rem 1rem;
	
	outline:none;
	
	font-size: 20px;
    text-decoration: none;
    cursor: pointer;
    border-left: 1px solid #f3f3f3;
    color: #f3f3f3;
    
    background-color: rgba(63,63,63 ,0.9);
    
    backdrop-filter: blur(4px);
	
	&:hover{
        background-color: rgba(31,31,31);
    }
`;