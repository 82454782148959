import { BackgroundBlock } from "../components/ui";
import { events } from "../data/events";

function EventsPage() {
	const title = "Мероприятия";

	return (
		<div className="flex flex-col gap-4 md:gap-8">
			<BackgroundBlock title={ title }/>
			<div
				className={ `px-4 md:px-12 ${ localStorage.getItem('vision') === '1' ? '' : 'lg:px-48' } flex flex-col gap-4` }>
				{
					events.map(el =>
						<div className="flex gap-2 items-center">
							{
								el.date &&
								<span
									className=""
								>
								{ el.date }
							</span>
							}
							{
								el.date &&
								<span
									className=""
								>
								/
							</span>
							}
							<a
								key={ `events-item-${ events.indexOf(el) }` }
								className="leading-5 font-bold underline hover:no-underline md:text-justify"
								href={ el.link }
								target="_blank"
								rel="noreferrer"
							>
								{ el.title }
							</a>
						</div>
					)
				}
			</div>

		</div>
	);
}

export default EventsPage;