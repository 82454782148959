export const events = [
	{
		date: "27.12.2024",
		title: "Положение о политике в отношении обработки персональных данных и о защите персональных данных работников ГКУЗ ЛО БСМЭ",
		link: "./pdf/events/polojenie-pd.pdf"
	},
	{
		date: "27.12.2024",
		title: "Как не стать жертвой мошенников и не совершить террористический акт",
		link: "./pdf/events/27.12.24.pdf"
	},
	{
		date: "25.12.2024",
		title: "Телекоммуникационная безопасность",
		link: "https://kpb.lenobl.ru/ru/o-komitete/telekommunikacionnaya-bezopasnost/"
	},
	{
		date: "25.12.2024",
		title: "Памятка о недопущении жестокого обращения с пожилыми людьми",
		link: "https://zdrav.lenobl.ru/ru/news/78591/"
	},
	{
		date: "24.12.2024",
		title: "План противодействия коррупции в ГКУЗ ЛО БСМЭ на 2025-2028 гг.",
		link: "./pdf/events/anticorruption03.pdf"
	},
	{
		date: "24.12.2024",
		title: "Методические материалы по формированию планов противодействия коррупции в органах исполнительной власти Ленинградской области",
		link: "./pdf/events/anticorruption02.pdf"
	},
	{
		date: "13.12.2024",
		title: "Популяризация здорового питания",
		link: "https://disk.yandex.ru/d/sF6VJ5axzNNj5w/%D0%97%D0%B4%D0%BE%D1%80%D0%BE%D0%B2%D0%BE%D0%B5%20%D0%BF%D0%BE%D0%BA%D0%BE%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5_%D0%90%D1%81%D0%B8%D1%8F%D1%82%20%D0%A5%D0%B0%D1%87%D0%B8%D1%80%D0%BE%D0%B2%D0%B0.mov"
	},
	{
		title: "Протокол совещания с субъектами Российской Федерации по вопросу развития и продвижения официального портала Минздрава России о здоровье",
		link: "./pdf/events/19.09.24.pdf"
	},
	{
		title: "Меры по предупреждению коррупции в государственных учреждениях и организациях Ленинградской области",
		link: "./pdf/events/anticorruption1.pdf"
	},
	{
		title: "Методические основы по организации работы по предупреждению коррупции в государственных организациях",
		link: "./pdf/events/anticorruption0.pdf"
	},
	{
		title: "Памятка по созданию контента для антинаркотической политики",
		link: "./pdf/events/24.05.24.pdf"
	},
	{
		title: "Год семьи 2024",
		link: "https://xn--2024-u4d6b7a9f1a.xn--p1ai/"
	},
	{
		title: "Профилактические медицинские осмотры и диспансеризация",
		link: "https://org.gnicpm.ru/profilakticheskie-mediczinskie-osmotry-i-dispanserizacziya-s-subtitrami"
	}
];